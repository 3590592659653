export const tableCellValueMixin = {
  methods: {
    getDisplayValue(item, field, options = {}) {
      // Lista de campos que tienen su propia lógica de renderizado
      const specialFields = [
        'action',
        'status',
        'statusLine',
        'active',
        'productive',
        'color',
        'name',
        'control_label',
        'description',
        'address1',
        'primaryLocation.name',
        'classificationType',
        'storage_type' // Añadido para Location.vue
      ];

      // Si es un campo especial, no lo procesamos aquí
      if (specialFields.includes(field)) {
        return null;
      }

      const value = item[field];
      
      if (value === null || value === undefined || value === '') {
        return '-';
      }

      // Para valores traducidos usando el método translate
      if (options.translate) {
        const translated = this.translate(value);
        return translated && translated !== '' ? translated : '-';
      }

      // Para valores que necesitan formato numérico
      if (options.number) {
        return isNaN(value) ? '-' : value;
      }

      return value || '-';
    }
  }
};
